import { CommonService } from 'dashboard-services';
import { loginActions } from 'primary-components';

import { basicTimeseriesActions } from 'actions/timeseries';
import { basicUploadActions } from 'actions/upload';
import properties from 'resources/constants/properties.json';
import { routing } from 'routing';
import { ExcelUtils, TimeSeriesUtils } from 'utils';

const FUNCTIONS_NAMESPACE = properties.functionsNamespace
const DASHBOARD_URL = properties.baseUrl.dashboardUrl

const RefreshCommands = (() => {

  const refreshCell = async e => {
    try {
      await Excel.run(async context => {
        const cell = context.workbook.getActiveCell();
        cell.calculate();
        await context.sync();
      });
    } catch (error) {
      console.error(error);
    }
    e.completed();
  }

  const refreshSheet = async e => {
    try {
      await Excel.run(async context => {
        const activeWorksheet = context.workbook.worksheets.getActiveWorksheet();
        await ExcelUtils.refreshAllFormulasInSheet({ worksheet: activeWorksheet, context })
      });
    } catch (error) {
      console.error(error);
    }
    e.completed();
  }

  const refreshAll = async e => {
    try {
      await Excel.run(async context => {
        await ExcelUtils.refreshAllFormulas({ context })
      });
    } catch (error) {
      console.error(error);
    }
    e.completed();
  }

  return {
    refreshCell,
    refreshSheet,
    refreshAll
  }
})()

Office.actions.associate("RefreshCommands.refreshCell", RefreshCommands.refreshCell);
Office.actions.associate("RefreshCommands.refreshSheet", RefreshCommands.refreshSheet);
Office.actions.associate("RefreshCommands.refreshAll", RefreshCommands.refreshAll);

const AuthCommands = (() => {

  const logOut = async e => {
    try {
      window["ngStore" + FUNCTIONS_NAMESPACE].dispatch(loginActions.logOut())
    } catch (error) {
      console.error(error)
    }
    e.completed()
  }

  return {
    logOut
  }
})()

Office.actions.associate("AuthCommands.logOut", AuthCommands.logOut);

const NavigateCommands = (() => {

  const openDocumentation = async e => {
    try {
      CommonService.openInNewTab(`${DASHBOARD_URL}${routing.EXTERNAL.documentation.url}`)
    } catch (error) {
      console.error(error)
    }
    e.completed()
  }

  const openDataLake = async e => {
    ExcelUtils.openTaskPaneComponent(routing.LAKE.name)
    e.completed()
  }
  const openTimeSeries = async e => {
    ExcelUtils.openTaskPaneComponent(routing.TS.name)
    e.completed()
  }
  const openLogin = async e => {
    ExcelUtils.openTaskPaneComponent(routing.LOGIN.name)
    e.completed()
  }
  const openSettings = async e => {
    ExcelUtils.openTaskPaneComponent(routing.SETTINGS.name)
    e.completed()
  }
  const openUpload = async e => {
    ExcelUtils.openTaskPaneComponent(routing.UPLOAD.name)
    e.completed()
  }

  return {
    openDocumentation,
    openDataLake,
    openTimeSeries,
    openLogin,
    openSettings,
    openUpload
  }
})()

Office.actions.associate("NavigateCommands.openDocumentation", NavigateCommands.openDocumentation);
Office.actions.associate("NavigateCommands.openDataLake", NavigateCommands.openDataLake);
Office.actions.associate("NavigateCommands.openTimeSeries", NavigateCommands.openTimeSeries);
Office.actions.associate("NavigateCommands.openLogin", NavigateCommands.openLogin);
Office.actions.associate("NavigateCommands.openSettings", NavigateCommands.openSettings);
Office.actions.associate("NavigateCommands.openUpload", NavigateCommands.openUpload);

const TimeSeriesCommands = (() => {
  const editTimeSeries = async e => {
    try {
      await Excel.run(async context => {
        const cell = context.workbook.getActiveCell();
        cell.load("address")
        cell.load("formulas")
        await context.sync()
        const formula = cell.formulas?.[0]?.[0]
              const parsed = TimeSeriesUtils.validateTable({
                cell,
                formula,
                returnItem: true
              })
              if(parsed) {
                ExcelUtils.openTaskPaneComponent(routing.TS.name)
                const dispatch =  window["ngStore" + FUNCTIONS_NAMESPACE].dispatch
                dispatch(basicTimeseriesActions.onChange("ADD NEW", "activeTab"))
                TimeSeriesUtils.SUPPORTED_TS_PARAMS.filter(paramName => parsed[paramName]).forEach(paramName => dispatch(basicTimeseriesActions.onChange(parsed[paramName], paramName)))
                dispatch(basicTimeseriesActions.onChange(parsed.groupName, "groupName"))
                dispatch(basicTimeseriesActions.onChange(parsed.symbols, "symbol"))
                dispatch(basicTimeseriesActions.onChange(parsed.values, "columns"))
                dispatch(basicTimeseriesActions.onChange(cell.address, "address"))
              }
        await context.sync()
      });
    } catch (error) {
      console.error(error)
    }
    e.completed()
  }

  return {
    editTimeSeries
  }
})()

Office.actions.associate("TimeSeriesCommands.editTimeSeries", TimeSeriesCommands.editTimeSeries);

const UploadCommands = (() => {

  const selectRange = async e => {
    try {
      ExcelUtils.openTaskPaneComponent(routing.UPLOAD.name)
      await window["ngStore" + FUNCTIONS_NAMESPACE].dispatch(basicUploadActions.defineFileRange())
    } catch (error) {
      console.error(error)
    }
    e.completed()
  }

  return {
    selectRange
  }
})()

Office.actions.associate("UploadCommands.selectRange", UploadCommands.selectRange);